import React from 'react';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import { useRouter } from 'next/router';

const Error = ({ errorMsg = 'Something went wrong', errorCode = 404 }) => {
  const router = useRouter();
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
      <div className="text-center">
        <Heading className="!text-5xl text-black-900 font-medium">
          {errorCode}
        </Heading>
        <Heading type="h4" className="mt-5 mb-6 font-medium text-black-900">
          {errorMsg}
        </Heading>
        <Button
          children="BACK TO HOME"
          varient="mediumContained"
          showIcon={false}
          onClick={() => router.push('/')}
        />
      </div>
    </div>
  );
};

export default Error;
